<style lang="less" scoped>
@import './index.less';
</style>
<template>
 
  <a-drawer :title="title" width="50%" :closable="false" :visible="visible" @close="onClose(false)" v-if="visible">
    <div class="m-b1">
    <a-select style="width:200px " :allowClear="true" v-model:value="name" show-search placeholder="名称" @change="seleAdd1" >
      <a-select-option v-for="opt in originalData" :key="opt.id" :title="opt.name" :value="opt.name">{{ opt.name }}</a-select-option>
    </a-select>
  </div>
    <div>
      <a-table :columns="[sortColumn,...columns]" :row-key="record => record.id" size="small" :data-source="listData" :loading="loading" :pagination="false" >
        <template #sort="{ index }">
          {{index+1}}
        </template>

        <template #operation="{ record }">
          <span>
            <a-radio-group v-model:value="record.authType" @change="changeAuth(record)">
              <a-radio-button :value="0">个人</a-radio-button>
              <a-radio-button :value="1">本部门</a-radio-button>
              <a-radio-button :value="2">一级部门</a-radio-button>
              <a-radio-button :value="9">全部</a-radio-button>
            </a-radio-group>
          </span>
        </template>
      </a-table>
    </div>
 
  </a-drawer>
</template>
<script>
import { reactive, toRefs } from 'vue'
import { dataAuth, updateDataAuth } from '@/api/system/employee'
import { message } from 'ant-design-vue'
export default {
  computed: {
  },

  setup () {
    const state = reactive({
      originalData:[],
      name: null,
      listData: [],
      empId: '',
      form: {},
      authValue: '',
      visible: false,
      loading: false,
      sortColumn: { title: '序号', slots: { customRender: 'sort' } },
      columns: [
        {
          title: '名称',
          dataIndex: 'name',
          key: 'name'
        },
        {
          title: '操作',
          dataIndex: 'operation',
          width: 400,
          slots: {
            customRender: 'operation'
          }
        }
      ]
    })
    const seleAdd1 = (e) => {
    if(!e){
      state.listData = state.originalData
      return
    }
    state.listData = state.originalData.filter(item => {
      return item.name.indexOf(e) !== -1
    })
    }

    const loadData = async (id) => {
      state.empId = id
      state.loading = true
      dataAuth(id).then((res) => {
        state.listData = res.data
        state.originalData = res.data
      })
      state.loading = false
    }
    const onClose = (recode) => {
     state.name = null
      state.visible = recode
    }
    const checkTree = (selectedKeys, e) => {
      state.selectedKeys = selectedKeys
    }
    const handleOk = () => {
      // 保存当前人员与资源的权限关系
    }

    const changeAuth = (recode) => {
      state.form = {
        id: recode.authId,
        resouceId: recode.id,
        empId: state.empId,
        authType: recode.authType
      }
      // 保存单次权限关系
      updateDataAuth(state.empId, state.form).then((res) => {
        if (res.code === 10000) {
          message.success('更新数据权限成功')
          if (recode.authId === null || recode.authId === '') {
            recode.authId = res.data
          }
        }
      })
    }

    return {
      loadData,
      ...toRefs(state),
      onclick,
      onClose,
      checkTree,
      handleOk,
      changeAuth,
      seleAdd1
    }
  }
}
</script>
